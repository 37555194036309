.theme.dark {
    #headerPpal {
        background: #363b46;
        border-bottom: solid 1px #363b46;
        .carTopWrap #buttonCarTop {
            background: #464a54;
            color: #fff;
            box-shadow: none;
            &.active,
            &:hover {
                background: var(--colorPpal);
            }
        }
    }
    #aplicationShop {
        color: #fff !important;
        background: #282b32;
    }

    #ListaCategorias .topCategorias .filters button {
        background: #363b46;
        color: #fff;
        box-shadow: none;
    }

    .productoItem {
        background: #363b46;
        box-shadow: none;
    }
    .textMedium {
        color: #fff;
    }

    .modalProd > .wrap > .content {
        background: #363b46;
    }
    .modalProd > .wrap > .content .detalle .footer {
        background: #282b32;
    }
    .modalProd > .wrap > .content .icon-cerrar {
        background: rgba(#fff, 0.2);
    }

    #headerPpal .carTopWrap #detailCarTop {
        background: #363b46;
        box-shadow: none;
    }

    #headerPpal
        .carTopWrap
        #detailCarTop
        section
        .wrapItem
        .item
        .det
        .buttons
        svg,
    #pagePago
        .compra
        .carrito
        .detalle
        .productos
        .itemCar
        > div:last-child
        svg {
        fill: #fff;
    }

    #pagePago .compra .top,
    #pagePago .datos h2,
    #pagePago .datos .h2 {
        border: none;
    }

    #pagePago .datos .wrapUsuario,
    #pagePago .compra .carrito {
        background: #363b46;
        box-shadow: none;
    }

    #pagePago .compra .carrito .detalle .productos .itemCar .nombre,
    #pagePago .compra .carrito .detalle .productos .itemCar .descripcion {
        color: #fff !important;
    }
    #pagePago .datos .datosUser button {
        background: #282b32;
        color: #fff !important;
        border: solid 1px #fff !important;
    }

    #pagePago .metodosWrap {
        background: #363b46;
        box-shadow: none;
    }
    .luka-tipo-pago figure {
        background: rgba($color: #fff, $alpha: 0.2);
        border-radius: 5px;
    }
    .luka ._form-control {
        border-radius: 5px;
    }

    #headerConfirm {
        background: #363b46;
        border-bottom: none;
    }

    #pageConfirm .infoProductos .productos,
    #pageConfirm .funcion,
    #pageConfirm .nota {
        background: #363b46;
        box-shadow: none;
    }
    #pageConfirm .infoProductos .productos .title-table,
    #pageConfirm .infoProductos .productos .itemCar {
        background: #363b46;
      
    }
    #pageConfirm .infoProductos .productos .itemCar{
      border-bottom: solid 1px #282b32;;
    }
}
