@import '../../assets/styles/variables.scss';
#headerPpal{
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 900;
    background: #fff;
    border-bottom: solid 1px $placeholder;
    .logo-header{
        display: block;
        align-self: flex-start;
        img{
            display: block;
            width: auto;
            max-height: 60px;
        }
    }
    .links{
        a.linkGen{
            font-size: 14px;
            margin: 0 20px;
            line-height: 14px;
            cursor: pointer;
        }
    }
    .carTopWrap{
        padding-left: 20px;
        position: relative;
        #buttonCarTop{
            color: $color1;
            height: 40px;
            border-radius: 8px;
            background: #fff;
            padding: 0 20px;
            border:none;
            box-shadow: 0 0 6px rgba($color: #A3ABC1, $alpha: .16);
            transition: ease-in-out all .3s;
            display: flex;
            align-items: center;
            i{
                margin-right: 10px;
            }
            span{
                font-size: 16px;
                font-weight: 600;
            }
            &:hover ,&.active{
                background: $color6;
                color: #fff;
            }
        }
        #overlayTopCar{
            position: fixed;
            height: 100%;
            width: 100%;
            top: 0px;
            left: 0;
            z-index: 899;
            background: rgba($color: #000000, $alpha: .16);
        }
        #detailCarTop{
            position: absolute;
            width: 340px;
            border-radius: 12px;
            height: 510px;
            background: #fff;
            z-index: 900;
            right: 0;
            top: 100%;
            margin-top: 20px;
            box-shadow: 0 0 10px rgba($color: #A3ABC1, $alpha: .30);
            display: flex;
            flex-direction: column;
            header{
                height: 50px;
                min-height: 50px;
                align-items: center;
                padding:5px 15px;
                display: flex;
                width: 100%;
                font-size: 18px;
                p{
                    font-weight: bold;
                    margin-bottom: 0;
                }
                border-bottom: solid 1px $placeholder;
            }
            section{
                flex-grow: 1;
                overflow: hidden;
                display: flex;
                width: 100%;
                .ps{width: 100%;}
                .wrapItem{
                    width: 100%;
                    padding:0 15px;
                    .item{
                        padding:20px 0;
                        border-bottom: solid 1px $placeholder;
                        align-items: stretch;
                        figure{
                            img{
                                display: block;
                                width: 48px;
                                height: 48px;
                                object-fit: cover;
                                object-position: center;
                                border-radius: 6px;
                            }
                            margin-bottom: 0;
                        }
                        .det{
                            margin:0 15px;
                            display: flex;
                            flex-direction: column;
                            align-items:space-between;
                            justify-content: space-between;
                            .nombre{
                                font-size: 14px;
                                
                            }
                            .buttons{
                                align-items: center;
                                i{
                                    font-size: 18px;
                                    line-height: 18px;
                                    height: 18px;
                                    cursor: pointer;
                                }
                                svg{
                                    cursor: pointer;
                                }
                                span{
                                    font-size: 12px;
                                    line-height: 12px;
                                    font-weight: bold;
                                    margin:0 5px
                                }
                            }
                        }
                        .price{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            margin-left: auto;
                            i{
                                font-size: 18px;
                                line-height: 18px;
                                height: 18px;
                                margin-left: auto;
                                cursor: pointer;
                                margin-top: auto;
                            }
                            .monto{
                                font-size: 14px;
                                
                            }
                            .totalVariante{
                                font-size: 11px;
                                font-weight: bold;
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .ps__rail-y{
                    right: 0 !important;
                    left: auto !important;
                }
            }
            footer{
                height: 160px;
                min-height: 160px;
                .info{
                    border-bottom: solid 1px $placeholder;
                    padding:10px 0;
                    p{
                        margin-bottom: 0;
                        padding:0 20px;
                        font-size: 16px;
                        
                        &:first-child{margin-bottom: 5px;}
                    }
                }
                .actions{
                    padding:20px;
                    button{
                        &::before{
                            content: "";
                            display: block;
                            position: absolute;
                            background: #000;
                            opacity: 0;
                            transition: ease-in-out all .2s;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                        }
                        span{position: relative;}
                        &:hover::before{
                            opacity: .1;
                        }
                        overflow: hidden;
                        position: relative;
                        background: $color6;
                        display: block;
                        width: 100%;
                        border:none;
                        max-width: 260px;
                        margin: 0 auto;
                        height: 40px;
                        line-height: 40px;
                        padding:0 10px;
                        border-radius: 8px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    &>span{
                        font-size: 12px;
                        
                        margin-top: 20px;
                        cursor: pointer;
                        display: block;
                        text-align: center;
                    }
                }
            }
            .noProducts{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    color: $color5;
                }
            }
        }
    }
    .widgetTopOptions{
        position: relative;
        &>a{
            i{
                font-size: 12px;
                line-height: 8px;
                height: 8px;
                margin-top: 2px;
            }
        }
        .overlay{
            position: fixed;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 1;
        }
        ul{
            padding:0px 0px;
            border-radius: 12px;
            background: #fff;
            box-shadow:$shadow;
            width: 200px;
            position: absolute;top: 100%;
            list-style: none;
            margin-top: 0px;
            right: 0;
            z-index: 2;
            overflow: hidden;
            transition: ease-in-out all .2s;
            opacity: 0;
            &.visible{
                opacity: 1;
                margin-top: 20px;
            }
            li{
                p{
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 14px;
                    margin-bottom: 0;
                    &:hover{
                        background: $placeholder;
                    }
                }
            }
        }
    }
}
#headerConfirm{
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    border-bottom: solid 1px $placeholder;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    .notice{
        text-align: center;
        h3{
            font-size: 40px;
            font-weight: bold;
            color: var(--text-color);
        }
        span{
            font-size: 15px;
        }
        p{
            font-size: 18px;
            font-weight: bold;
        }
    }
}
.cintilloAbierto{
    width:100%;
    background:$placeholder;
    position: sticky;
    top: 80px;
    z-index: 800;
    p{
        padding: 10px 0;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
    }
}